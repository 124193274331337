<template>
  <v-card id="account-setting-card">
    <v-card-title class="justify-center">
      <span class="text-h5"><strong>Bill Of Lading</strong></span>
    </v-card-title>
    <!-- tabs -->

    <!-- <v-tabs v-model="active_tab">
            <template v-for="tab in tabs">
                <v-tab @change="current_component = tab.value" :key="tab.value">
                    <span>{{ tab.title }} </span>
                </v-tab> -->

    <v-tabs v-model="active_tab">
      <v-tab key="0" :disabled="readOnlyField === true">
        <span>OS (oculus sinister)</span>
      </v-tab>
      <v-tab key="1" :disabled="readOnlyField1 === true">

        <span>OD (oculus dextrus)</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="active_tab">
      <v-tab-item key="0">
        <BillOfLadingOs />
      </v-tab-item>

      <v-tab-item key="0">
        <BillOfLadingOd />

      </v-tab-item>
    </v-tabs-items>

    <!-- </template>
        </v-tabs>
        <component  :is="current_component" :key="current_component">
        </component> -->


  </v-card>
</template>

<script>
import LadingBill from '@/service/LadBilling.service'
// const service = new LadingBill()

import BillOfLadingOs from './BillOfLadingOs.vue'
import BillOfLadingOd from './BillOfLadingOd.vue'

export default {
  components: {
    BillOfLadingOs,
    BillOfLadingOd,
  },

  setup() {
    return {

      // tab: "tab2",
      readOnlyField: false,
      readOnlyField1: false,
      active_tab: 0,

    }
  },
  watch: {
    options: {
      handler() {
        this.AdverseList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.AdverseList()
    this.BillOfLadingOSOD();
  },



  methods: {
    async AdverseList() {
      const DonorId = this.$route.params.donor_id
      const service = new LadingBill()
      let response = await service.GetLadingBillOs(DonorId)
      let responseOd = await service.GetLadingBillOd(DonorId);

      console.log("OSSS", responseOd);

      if (response.os_id == null || response.tissue_outcome == "Research") {
        //console.log("shubham")
        this.readOnlyField = true
        this.active_tab = "1"
      } if (response.od_id == null || responseOd.tissue_outcome == "Research") {
        //console.log("bhati")
        this.readOnlyField1 = true
        this.active_tab = "0"
      } if (response.os_id == null && response.od_id == null) {
        //console.log("bhati new")
        this.readOnlyField = true
        this.readOnlyField1 = true
        this.active_tab = "2"
      }
    },


    BillOfLadingOSOD() {
      const tissue_id = this.$route.params.tissue_id;
      if (tissue_id.match("OD")) {
        this.readOnlyField = true
        this.active_tab = "1"
      } else {
        this.readOnlyField1 = true
        this.active_tab = "0"
      }
    }

  }
}

</script>

	

