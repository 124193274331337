<template>
    <v-card>

        <v-row class="ml-3  mr-5 pt-5 mb-3">

            <v-col cols="12" md="6">
                <v-text-field v-model="ladingBill.bill_to" label="Bill To" dense hide-details readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="ladingBill.invoice_no" label="Invoice No" dense hide-details readonly>
                </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="ladingBill.hospital_address" label="Hospital Address" dense hide-details
                    readonly></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
                <v-datetime-picker v-model="date" time-format="HH:mm" date-format="dd-MM-yyyy" label="Date"
                    :text-field-props="textFieldProps">
                </v-datetime-picker>
            </v-col>
            <v-col cols="12" md="6">
                <v-datetime-picker v-model="surgeryDateTime" :text-field-props="textFieldProps" time-format="HH:mm" date-format="dd-MM-yyyy" label="Surgery Date">
                </v-datetime-picker>
                <!-- <v-text-field v-model="surgeryDateTime" label="Surgery Date" dense hide-details readonly>
                </v-text-field> -->
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="donorDeathDate" label="Donor Death Date" dense hide-details
                    readonly>
                </v-text-field>
                <!-- <v-datetime-picker v-model="donorDeathDate" time-format="HH:mm:ss"
                    label="Donor Death Date" :text-field-props="textFieldProps">
                </v-datetime-picker> -->
            </v-col>

            <v-col cols="12" md="6">
                <v-text-field v-model="ladingBill.tissue_no" label="Tissue Number" dense hide-details readonly>
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
                <v-autocomplete v-model="ladingBill.tissue_category" label="Tissue Category" dense
                    :items="HospitalCategory"></v-autocomplete>
            </v-col>
        </v-row>
        <!-- 
            <v-col cols="12" md="6">
                <v-text-field label="Originating EB" dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field label="Originating EB#" dense hide-details></v-text-field>
            </v-col> -->


        <v-row class="ml-3  mr-5 pt-5 mb-3">
            <v-col class="mt-5" cols="12" md="3">
                <p class="body-2 mt-2 mb-0 "><strong>Quantity</strong></p>
            </v-col>
            <v-col class="mt-5" cols="12" md="3">
                <p class="body-2 mt-2 mb-0 "><strong>Description</strong></p>
            </v-col>

            <v-col class="mt-5" cols="12" md="3">
                <p class="body-2 mt-2 mb-0 "><strong>Reimbursement Amount</strong></p>
            </v-col>
        </v-row>
        <v-row class="ml-3  mr-5 pt-5 mb-3 align-end">
            <v-col cols="12" class="px-0 py-0">
                <template v-for="obj in quantity_arr">
                    <v-row class="mx-0">
                        <v-col cols="12" md="3">
                            <v-text-field v-model="obj.quantity" @keypress="onlyNumber" hide-details
                                :readonly="readOnlyField1 === true"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete v-if="obj.showSelectDesc" class="mt-6" v-model="obj.description"
                                label="Tissue Description" dense :items="TissueDescription" @change="tissue_desc">
                            </v-autocomplete>
                            <v-text-field v-else="obj.showSelectDesc" v-model="obj.description" hide-details
                                :readonly="readOnlyField1 === true">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field v-model="obj.amount" @keypress="onlyNumber" hide-details
                                :readonly="readOnlyField1 === true"></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" md="3">
                            <v-btn color="error" class="mt-2"> Delete row </v-btn>
                        </v-col> -->
                    </v-row>
                </template>
            </v-col>
            <v-col cols="3">
                <v-btn color="primary" v-if="saveBtn" @click.prevent="addRecharge"> Add row </v-btn>
            </v-col>
        </v-row>

        <v-row class="ml-3  mr-5 pt-5 mb-3">
            <v-col cols="12" class="mt-5" md="6">
                <v-text-field v-model="ladingBill.Terms" label="Terms" dense hide-details
                    :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
            <v-col class="mt-5" cols="12" md="6">
                <v-text-field v-model="ladingBill.reimbursement_amount" label="Reimbursement Amount" dense hide-details>
                </v-text-field>
            </v-col>

        </v-row>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="saveBtn" class="mb-5" @click="save"> Save </v-btn>
        </v-card-actions>
        <v-snackbar v-model="snackbar" :timeout="timeout">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import donorService from '@/service/Donor.service'
import LadingBill from '@/service/LadBilling.service'
const service = new LadingBill()
import moment from 'moment';
const baseUrl = "https://api.ebsr.in/";
const localData = JSON.parse(localStorage.getItem("token"));
const token = localData.token;

export default {
    data() {
        return {
            formtype: '',
            timeout: 2000,
            readOnlyField1: false,
            HospitalCategory: ['Therapeutic', 'Op.PK'],
            TissueDescription: ['Cornea Processing Charges', 'Shipping Charges', 'Others'],
            snackbar: false,
            snackbarText: '',
            quantity_arr: [
                { quantity: '', description: '', amount: '', showSelectDesc: true },
            ],
            ladingBill: {
                "hospital_email": "",
                "tissue_no": "",
                "donor_id": 0,
                "OD_and_OS": "",
                "bill_to": "",
                "invoice_no": "",
                "date": "",
                // "Originating_EB": "",
                // "Originating_EB1": "",
                "quantity": 0,
                "description": "",
                "recipient": "",
                "reimbursement": "",
                "amount": 0,
                "Terms": "",
                "reimbursement_amount": 0,
                "surgery_date_time": '',
                "donor_death_date": '',
                "tissue_category": ''


            },
            donor_id: this.$route.params.donor_id,
            saveBtn: true,
            textFieldProps: {
                dense: 'true',
                "hide-details": 'true'
            }
        }

    },
    computed: {

        date: {
            get() {
                return this.ladingBill.date ? moment.utc(this.ladingBill.date).format("DD-MM-YYYY HH:mm"
                ) : moment().format("DD-MM-YYYY HH:mm");

            },
            set(value) {
                this.$set(this.ladingBill, "date", moment(value).format(
                    "YYYY-MM-DD HH:mm"
                ));
            },
        },

        donorDeathDate: {
            get() {
                return this.ladingBill.donor_death_date ? moment.utc(this.ladingBill.donor_death_date).format("DD-MM-YYYY HH:mm") : moment().format("DD-MM-YYYY HH:mm");
            },
            set(value) {
                this.$set(this.donor_death_date, "date", moment(value).format("YYYY-MM-DD HH:mm"));
            },
        },

        surgeryDateTime: {
            get() {
                return moment.utc(this.ladingBill.surgery_date_time).format("DD-MM-YYYY HH:mm");
            },
            set(value) {
                this.$set(this.ladingBill, "surgery_date_time", moment(value).format("YYYY-MM-DD HH:mm"));
            },
        },
        
    },
    watch: {
        quantity_arr: {
            handler(newValue, oldValue) {
                this.ladingBill.reimbursement_amount = this.quantity_arr.reduce((acc, val) => { return acc + (val.amount ? Number(val.amount) : 0) }, 0)
            },
            deep: true
        }
    },
    async mounted() {
        await this.getLadingBillling()
        this.getdonorList()
        this.tissue_desc()
    },

    methods: {

        tissue_desc() {
            const desc_data = this.quantity_arr;
            for (let i = 0; i < desc_data.length; i++) {
                console.log(desc_data[i].description);
                if (desc_data[i].description == "Others") {
                    desc_data[i].showSelectDesc = false
                    this.quantity_arr[i].description = '';
                }
            }
        },
        async getdonorList() {
            this.listLoading = true
            const service = new donorService()
            let response = await service.donorList()
            if (response) {
                this.permissionData = response.permissions
                var formData = this.permissionData[10]
                this.formtype = this.permissionData[11]
                if (formData.view == "1" && formData.edit == null || formData.view == "1" && formData.edit == "0") {
                    this.readOnlyField1 = true
                    this.saveBtn = false
                }
            } else {
                this.totallist = 0
            }
            this.listLoading = false
        },

        addRecharge() {
            this.quantity_arr.push({ quantity: null, showSelectDesc: true })
        },

        async getLadingBillling() {
            try {
                let response = await service.GetLadingBillOd(this.donor_id)
                if (response.surgery_date_time === '') {
                    response.surgery_date_time = 'N/A'
                } else {
                    response.surgery_date_time = response.surgery_date_time
                }
                //   console.log("hello",response)
                if (response.status === 1) {
                    this.saveBtn = false
                } else {
                    this.saveBtn = true
                }
                this.ladingBill = structuredClone(response)
                this.quantity_arr = JSON.parse(this.ladingBill['quantity'])

            } catch (error) {
                console.log(error);
            }
        },
        async save() {
            if (!this.ladingBill.date) this.ladingBill['date'] = moment().utcOffset("+5:30").format("YYYY-MM-DD HH:mm");
            await this.saveBillOfLading(this.ladingBill)
            // if(this.formtype.view != '0' && this.formtype.view != null){
            //     this.$router.push({name:'AdverseReaction'});
            // }

        },
        async saveBillOfLading(dataObj) {
            if(dataObj.surgery_date_time == 'Invalid date' || dataObj.surgery_date_time == "" || dataObj.surgery_date_time == "N/A" || dataObj.surgery_date_time === null){
                dataObj.surgery_date_time = null
            }else{
                dataObj.surgery_date_time = moment.utc(dataObj.surgery_date_time).format('YYYY-MM-DD HH:mm')
            }
            try {
                let saveObj = {
                    "tissue_no": dataObj.tissue_no,
                    "donor_id": this.$route.params.donor_id,
                    "OD_and_OS": 'OD',
                    "bill_to": dataObj.bill_to,
                    "invoice_no": dataObj.invoice_no,
                    "tissue_category": dataObj.tissue_category,
                    "date": moment.utc(dataObj.date).format('YYYY-MM-DD HH:mm'),
                    "surgery_date_time": dataObj.surgery_date_time,
                    "donor_death_date": moment.utc(dataObj.donor_death_date).format('YYYY-MM-DD HH:mm'),
                    "hospital_email": dataObj.hospital_email,
                    "quantity": this.quantity_arr,
                    "Terms": dataObj.Terms,
                    "reimbursement_amount": dataObj.reimbursement_amount
                }
                // console.log(saveObj, "called saved function", saveObj.quantity);
                let response = await service.saveBillOfLading(saveObj)
                // if(response.)
                this.snackbarText = response.msg;
                this.snackbar = true
                await this.getLadingBillling()

            } catch (error) {
                this.snackbarText = response.msg;
                this.snackbar = true
            }
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which
            if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
                $event.preventDefault()
            }
        },
    },
}
</script>
